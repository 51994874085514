import Department from "../enums/Department";
import Category from "../enums/Category";

export default class User {
    public id: number;
    public firstname: string;
    public lastname: string;
    public department: Department;
    public roleDepartment: string;
    public picture: string;
    public category: Category;
    public permissions: Array<number>;

    constructor(id = 0, firstname = '', lastname = '', department = Department.NONE, roleDepartment = '', picture = '', category = Category.NONE, permissions: Array<number> = []) {
        this.id = id;
        this.firstname = firstname;
        this.lastname = lastname;
        this.department = department;
        this.roleDepartment = roleDepartment;
        this.picture = picture;
        this.category = category;
        this.permissions = permissions;
    }
}