import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import './addRestau.css';
import React, { useState } from 'react';
import RappelsClient from '../modal/rappelsClient';
import PolitiqueAnnulation from '../modal/politiqueAnnulation';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import {
    Restaurant, Cuisine,
    PolitiqueAnnulation as PolitiqueAnnulationType,
    RappelsClient as RappelsClientType,
    Salle,
    Periode,
    Service,
    Menu
} from '../types/types';
import { restaurantService } from '../api/restaurantService';
import { useNavigate } from 'react-router-dom';

const Alert = React.forwardRef < HTMLDivElement, AlertProps> (function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function AddRestau() {

    const navigate = useNavigate();

    const initialFormData: Restaurant = {
        id: 0,
        nom: '',
        email: '',
        localisation: '',
        webSite: '',
        type: true,
        hotelId: 0,
        cuisine: {
            id: 0,
            espace: true,
            planning: true,
            couleur: '#C34A36',
            posteService: '',
            nomChefCuisine: '',
            prenomChefCuisine: '',
            restaurantId: 0
        },
        menus: [
        ],
        periodes: [
        ],
        politiqueAnnulation: {
            id: 0,
            conditionAnnulation: '',
            restaurantId: 0
        },
        rappelsClient: {
            id: 0,
            codeVestimentaireHomme: '',
            codeVestimentaireFemme: '',
            animauxCompagnie: '',
            enfantsCondition: '',
            autreCondition: '',
            restaurantId: 0
        },
        salle: {
            id: 0,
            espace: true,
            planning: true,
            couleur: 'rgba(164, 226, 31, 0.63)',
            posteService: '',
            nomChefService: '',
            prenomChefService: '',
            restaurantId: 0
        },
        services: [
        ],
        tables: [
        ]
    };

    const [formData, setFormData] = useState < Restaurant > (initialFormData);

    // Periode
    const [dateDebut, setDateDebut] = useState < Date | null > (null);
    const [dateFin, setDateFin] = useState < Date | null > (null);

    // Service
    const [serviceType, setServiceType] = useState < string > ('Non-Stop');
    const [timeDebut, setTimeDebut] = useState < string > ('');
    const [timeFin, setTimeFin] = useState < string > ('');

    // Menu
    const [menuNom, setMenuNom] = useState < string > ('');
    const [menuPrix, setMenuPrix] = useState < number | null > (null);
    const [menuIsPDJ,] = useState < boolean > (false);

    // Table
    const [tableNumero, setTableNumero] = useState < number | null > (null);
    const [tableNbPersonne, setTableNbPersonne] = useState < number | null > (null);

    const resetFormData = () => {
        setFormData(initialFormData);
        setDateDebut(new Date());
        setDateFin(new Date());
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleAddPeriode = () => {
        if (!dateDebut) {
            alert("Veuillez remplir tous les champs avant d'ajouter une période.");
            return;
        }

        if (formData.type && (!dateFin || dateDebut > dateFin)) {
            if (!dateFin) {
                alert("Veuillez remplir tous les champs avant d'ajouter une période.");
                return;
            }

            if (dateDebut > dateFin) {
                alert("La date de début doit être inférieure à la date de fin.");
                return;
            }
        }

        const newPeriode = {
            id: formData.periodes.length + 1,
            dateDebutString: dateDebut ? dateDebut.toISOString().split('T')[0] : '',
            dateFinString: dateFin ? dateFin.toISOString().split('T')[0] : '',
            restaurantId: 0,
        };

        setFormData({
            ...formData,
            periodes: [...formData.periodes, newPeriode],
        });
    };

    const handleDeletePeriode = (id: number) => {
        setFormData(prevState => ({
            ...prevState,
            periodes: prevState.periodes.filter(periode => periode.id !== id),
        }));
    };

    const handleAddService = () => {
        if (!serviceType || !timeDebut || !timeFin) {
            alert("Veuillez remplir tous les champs avant d'ajouter un service.");
            return;
        }

        if (timeDebut > timeFin) {
            alert("L'heure de début doit être inférieure à l'heure de fin.");
            return;
        }

        const newService: Service = {
            id: formData.services.length + 1,
            type: serviceType,
            restaurantId: 0,
            index: formData.services.length + 1,
            heureDebutString: timeDebut,
            heureFinString: timeFin,
        };

        setFormData({
            ...formData,
            services: [...formData.services, newService],
        });
    };

    const handleDeleteService = (id: number) => {
        setFormData(prevState => ({
            ...prevState,
            services: prevState.services.filter(service => service.id !== id),
        }));
    };

    const handleAddMenu = () => {
        if (!menuNom || !menuPrix) {
            alert("Veuillez remplir tous les champs avant d'ajouter un menu.");
            return;
        }

        const newMenu: Menu = {
            id: formData.menus.length + 1,
            nom: menuNom,
            prix: menuPrix,
            isPDJ: menuIsPDJ,
            restaurantId: 0,
        };

        setFormData({
            ...formData,
            menus: [...formData.menus, newMenu],
        });
    };

    const handleDeleteMenu = (id: number) => {
        setFormData(prevState => ({
            ...prevState,
            menus: prevState.menus.filter(menu => menu.id !== id),
        }));
    };

    const handleAddTable = () => {
        if (!tableNumero || !tableNbPersonne) {
            alert("Veuillez remplir tous les champs avant d'ajouter une table.");
            return;
        }

        if (tableNumero < 0 || tableNbPersonne < 0) {
            alert("Veuillez renseigner des valeurs positives.");
            return;
        }

        const newTable = {
            id: formData.tables.length + 1,
            numero: tableNumero,
            nbPersonne: tableNbPersonne,
            restaurantId: 0,
        };

        setFormData({
            ...formData,
            tables: [...formData.tables, newTable],
        });
    };

    const handleDeleteTable = (id: number) => {
        setFormData(prevState => ({
            ...prevState,
            tables: prevState.tables.filter(table => table.id !== id),
        }));
    };

    const handleRappelsClientUpdate = (updatedRappelsClient: RappelsClientType) => {
        setFormData(prevState => ({
            ...prevState,
            rappelsClient: updatedRappelsClient
        }));
    };

    const handlePolitiqueAnnulationUpdate = (updatedPolitiqueAnnulation: PolitiqueAnnulationType) => {
        setFormData(prevState => ({
            ...prevState,
            politiqueAnnulation: updatedPolitiqueAnnulation
        }));
    };

    const handleSalleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>, field: keyof Salle) => {
        const updatedValue = event.target.checked;

        setFormData(prevState => ({
            ...prevState,
            salle: {
                ...prevState.salle,
                [field]: updatedValue,
            },
        }));
    };

    const handleSalleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const updatedColor = event.target.value;

        setFormData(prevState => ({
            ...prevState,
            salle: {
                ...prevState.salle,
                couleur: updatedColor,
            },
        }));
    };

    const handleSalleInputTextChange = (event: React.ChangeEvent<HTMLInputElement>, field: keyof Salle) => {
        const updatedValue = event.target.value;

        setFormData(prevState => ({
            ...prevState,
            salle: {
                ...prevState.salle,
                [field]: updatedValue,
            },
        }));
    };

    const handleCuisineSwitchChange = (event: React.ChangeEvent<HTMLInputElement>, field: keyof Cuisine) => {
        const updatedValue = event.target.checked;

        setFormData(prevState => ({
            ...prevState,
            cuisine: {
                ...prevState.cuisine,
                [field]: updatedValue,
            },
        }));
    };

    const handleCuisineSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const updatedColor = event.target.value;

        setFormData(prevState => ({
            ...prevState,
            cuisine: {
                ...prevState.cuisine,
                couleur: updatedColor,
            },
        }));
    };

    const handleCuisineInputTextChange = (event: React.ChangeEvent<HTMLInputElement>, field: keyof Cuisine) => {
        const updatedValue = event.target.value;

        setFormData(prevState => ({
            ...prevState,
            cuisine: {
                ...prevState.cuisine,
                [field]: updatedValue,
            },
        }));
    };

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            const newRestaurant = await restaurantService.addRestaurant(formData);

            setOpenSnackbar(true);
            resetFormData();

            navigate(`/restaurant/${newRestaurant.id}`);
        } catch (error) {
            setOpenErrorSnackbar(true);
            console.error('Failed to add restaurant:', error);
        }
    };

    const [showRappelsClientModal, setShowRappelsClientModal] = useState(false);
    const openRappelsClientModal = () => { setShowRappelsClientModal(true); };
    const closeRappelsClientModal = () => { setShowRappelsClientModal(false); };

    const [showPolitiqueAnnulationModal, setPolitiqueAnnulationModal] = useState(false);
    const openPolitiqueAnnulationModal = () => { setPolitiqueAnnulationModal(true); };
    const closePolitiqueAnnulationModal = () => { setPolitiqueAnnulationModal(false); };

    const [showAnnuelDatePicker, setShowAnnuelDatePicker] = useState(true);
    const handleAnnuelClick = () => {
        setShowAnnuelDatePicker(false);
        setFormData(prevState => ({
            ...prevState,
            type: false
        }));
    };

    const handleSaisonnierClick = () => {
        setShowAnnuelDatePicker(true);
        setFormData(prevState => ({
            ...prevState,
            type: true
        }));
    };

    const colorOptions = [
        { value: "rgba(164, 226, 31, 0.63)", name: "Light Green" },
        { value: "#70DBEB", name: "Light Blue" },
        { value: "#5E5EAF", name: "Light Purple" },
        { value: "#8E6FAD", name: "Purple" },
        { value: "#D67AB1", name: "Pinkish" },
        { value: "#E05D5D", name: "Light Red" },
        { value: "rgb(149 52 36)", name: "Dark Red" },
        { value: "rgb(145 145 145)", name: "Salmon" },
    ];

    return (
        <div className='restau-section'>

            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={openSnackbar} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Restaurant ajouté avec succès !
                </Alert>
            </Snackbar>

            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={openErrorSnackbar} autoHideDuration={6000} onClose={() => setOpenErrorSnackbar(false)}>
                <Alert onClose={() => setOpenErrorSnackbar(false)} severity="error" sx={{ width: '100%' }}>
                    Échec de l'ajout du restaurant
                </Alert>
            </Snackbar>

            <form onSubmit={handleSubmit}>
                <div className='restau-form'>
                    <h1>Nouvel espace restauration</h1>
                    <div className='restau-main-container'>
                        <div className='restau-column'>
                            <div style={{ marginTop: "20px", width: "100%" }}>
                                <label htmlFor="email-restau" className='label-restau'>Email du restaurant</label>
                                <input type="email" id='email-restau' className='text-restau' required
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                />
                            </div>

                            <div style={{ marginTop: "20px", width: "100%" }}>
                                <label htmlFor="nom-restau" className='label-restau'>Nom du restaurant - Dèpartement</label>
                                <input type="text" id='nom-restau' className='text-restau' required
                                    name="nom"
                                    value={formData.nom}
                                    onChange={handleInputChange}
                                />
                            </div>

                            <div id='btnDiv' style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                <button type='button' onClick={handleAnnuelClick} className={showAnnuelDatePicker ? '' : 'restau-btn-clicked'}>
                                    Annuel
                                </button>

                                <button type='button' onClick={handleSaisonnierClick} className={showAnnuelDatePicker ? 'restau-btn-clicked' : ''}>
                                    Saisonnier
                                </button>
                            </div>

                            <div style={{ marginTop: "20px", width: "100%" }}>
                                <label htmlFor="date-debut">Date d'ouverture</label>
                                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "5px", alignItems: "flex-end" }}>

                                    <div style={{ width: "37%", display: "flex", flexDirection: "column" }}>
                                        <label htmlFor="dateDebut">Du</label>
                                        <input
                                            type="date"
                                            name="dateDebut"
                                            id="dateDebut"
                                            onChange={(e) => setDateDebut(new Date(e.target.value))}
                                        />
                                    </div>

                                    {showAnnuelDatePicker && (
                                        <div style={{ width: "37%", display: "flex", flexDirection: "column" }}>
                                            <label htmlFor="dateFin">Au</label>
                                            <input
                                                type="date"
                                                name="dateFin"
                                                id="dateFin"
                                                onChange={(e) => setDateFin(new Date(e.target.value))}
                                            />
                                        </div>
                                    )}

                                    <button type='button' className='restau-add-btn' onClick={handleAddPeriode}>
                                        ✓
                                    </button>
                                </div>

                                <div className='restau-periode-container' style={{ marginTop: "5px", maxHeight: "100px" }}>
                                    {
                                        formData?.periodes.map((periode, index) => (
                                            <div key={index} className='restau-service-time'>
                                                <h4 style={{ margin: "7px" }}>
                                                    Date {periode.dateDebutString} {showAnnuelDatePicker ? ' - ' + periode.dateFinString : ''}
                                                </h4>
                                                <div>
                                                    <DeleteForeverIcon
                                                        style={{ fontSize: "25px", cursor: "pointer", color: "#134563" }}
                                                        onClick={() => handleDeletePeriode(periode.id)}
                                                    />
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>

                            <div style={{ marginTop: "20px", width: "100%" }}>
                                <label className='label-restau'>Horaires de service</label>
                                <RadioGroup
                                    row
                                    className='radio-restau'
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="serviceType"
                                    onChange={(e) => setServiceType(e.target.value)}
                                >
                                    <FormControlLabel value="Non-Stop" control={<Radio className="custom-radio" />} label="Non-Stop" />
                                    <FormControlLabel value="Déjeuner" control={<Radio className="custom-radio" />} label="Déjeuner" />
                                    <FormControlLabel value="Dîner" control={<Radio className="custom-radio" />} label="Dîner" />
                                </RadioGroup>
                            </div>

                            <div style={{ width: "100%", display: "flex", justifyContent: "space-between", marginTop: "5px", alignItems: "flex-end" }}>
                                <div style={{ width: "40%", display: "flex", flexDirection: "column" }}>
                                    <label htmlFor="serviceStartTime">Du</label>
                                    <input
                                        type="time"
                                        id="serviceStartTime"
                                        onChange={(e) => setTimeDebut(e.target.value)}
                                    />
                                </div>

                                <div style={{ width: "40%", display: "flex", flexDirection: "column" }}>
                                    <label htmlFor="serviceEndTime">Au</label>
                                    <input
                                        type="time"
                                        id="serviceEndTime"
                                        onChange={(e) => setTimeFin(e.target.value)}
                                    />
                                </div>

                                <button type='button' className='restau-add-btn' onClick={handleAddService}>
                                    ✓
                                </button>
                            </div>
                            <div className='restau-service-container' style={{ marginTop: "5px", width: "100%", maxHeight: "100px" }}>
                                {
                                    formData.services.map((service) => (
                                        <div key={`${service.type}-${service.index}`} className='restau-service-time'>
                                            <h4 style={{ margin: "7px" }}>
                                                {service.type} - {service.index} Service - {service.heureDebutString}/{service.heureFinString}
                                            </h4>
                                            <div>
                                                <DeleteForeverIcon
                                                    style={{ fontSize: "25px", cursor: "pointer", color: "#134563" }}
                                                    onClick={() => handleDeleteService(service.id)}
                                                />
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>

                        <div className='restau-column'>
                            <div style={{ marginTop: "20px", width: "100%" }}>
                                <label htmlFor="numero" className='label-restau'>Nombre de tables</label>
                                <div className='restau-column2' style={{ alignItems: "flex-end", justifyContent: "space-between" }}>
                                    <input
                                        type="number"
                                        id='numero'
                                        className='text-restau'
                                        placeholder='Nom'
                                        style={{ width: "40%" }}
                                        onChange={(e) => setTableNumero(parseInt(e.target.value))}

                                    />
                                    <input
                                        type="number"
                                        id="nbPersonne"
                                        className='text-restau'
                                        placeholder='Personne'
                                        style={{ width: "30%" }}
                                        onChange={(e) => setTableNbPersonne(parseInt(e.target.value))}
                                    />

                                    <button type='button' className='restau-add-btn' onClick={handleAddTable}>
                                        ✓
                                    </button>
                                </div>

                                <div className='restau-table-container' style={{ marginTop: "5px", width: "100%", maxHeight: "100px" }}>
                                    {
                                        formData.tables.map((table) => (
                                            <div key={table.id} className='restau-service-time'>
                                                <h4 style={{ margin: "7px" }}>
                                                    {table.numero} - {table.nbPersonne} Personnes
                                                </h4>
                                                <div>
                                                    <DeleteForeverIcon
                                                        style={{ fontSize: "25px", cursor: "pointer", color: "#134563" }}
                                                        onClick={() => handleDeleteTable(table.id)}
                                                    />
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            <div style={{ marginTop: "20px", width: "100%" }}>
                                <label htmlFor="nom" className='label-restau'>Menu</label>
                                <div
                                    className='restau-column2'
                                    style={{
                                        alignItems: 'flex-end',
                                        justifyContent: "space-between"
                                    }}
                                >
                                    <input
                                        type="text"
                                        id="nom"
                                        className='text-restau'
                                        placeholder='Type de Menu'
                                        style={{ width: "50%" }}
                                        onChange={(e) => setMenuNom(e.target.value)}
                                    />
                                    <input
                                        type="number"
                                        id="prix"
                                        className='text-restau'
                                        placeholder='€'
                                        style={{ width: '50px' }}
                                        onChange={(e) => setMenuPrix(parseFloat(e.target.value))}
                                    />

                                    {/* <div style={{ display: "flex", alignItems: "center", marginLeft:"5px" }}>
                                        <label htmlFor="isPDJ" className='label-restau'>PDJ</label>
                                        <input
                                            type="checkbox"
                                            id="isPDJ"
                                            className='text-restau'
                                            style={{ width: '20px', height: '20px', marginLeft: '10px' }}
                                            onChange={(e) => setMenuIsPDJ(e.target.checked)}
                                        />
                                    </div> */}

                                    <button type='button' className='restau-add-btn' onClick={handleAddMenu}>
                                        ✓
                                    </button>
                                </div>

                                <div className='restau-menu-container' style={{ marginTop: "5px", width: "100%", maxHeight: "100px" }}>
                                    {
                                        formData.menus.map((menu) => (
                                            <div key={menu.id} className='restau-service-time'>
                                                <h4 style={{ margin: "7px" }}>
                                                    {menu.nom} - {menu.prix} €
                                                </h4>
                                                <div>
                                                    <DeleteForeverIcon
                                                        style={{ fontSize: "25px", cursor: "pointer", color: "#134563" }}
                                                        onClick={() => handleDeleteMenu(menu.id)}
                                                    />
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>

                            </div>

                            <div style={{ marginTop: "30px", width: "100%" }}>
                                <button type="button" className='restau-btn' onClick={openRappelsClientModal}>
                                    Rappels clients
                                </button>

                                {showRappelsClientModal && (
                                    <RappelsClient
                                        initialData={formData.rappelsClient}
                                        onUpdate={handleRappelsClientUpdate}
                                        onClose={closeRappelsClientModal}
                                    />
                                )}

                                <button type="button" className='restau-btn' style={{ marginTop: "30px" }} onClick={openPolitiqueAnnulationModal}>
                                    Politiques d'annulation
                                </button>
                                {showPolitiqueAnnulationModal && (
                                    <PolitiqueAnnulation
                                        initialData={formData.politiqueAnnulation}
                                        onUpdate={handlePolitiqueAnnulationUpdate}
                                        onClose={closePolitiqueAnnulationModal}
                                    />
                                )}
                            </div>

                            <div style={{ marginTop: "20px", width: "100%" }}>
                                <div>
                                    <label htmlFor="site-restau" className='label-restau'>Site Internet du restaurant</label>
                                    <input type="text" id='site-restau' className='text-restau'
                                        name="webSite"
                                        value={formData.webSite}
                                        onChange={handleInputChange}
                                    />
                                </div>

                                <div style={{ marginTop: "20px", width: "100%" }}>
                                    <label htmlFor="addresse-restau" className='label-restau'>Addresse du restaurant</label>
                                    <input type="text" id='addresse-restau' className='text-restau'
                                        name="localisation"
                                        value={formData.localisation}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='restau-column'>

                            <div className='restau-column3' style={{ marginTop: "20px" }}>
                                <div className="row">
                                    <label className='label-restau column'></label>
                                    <label className='label-restau column' style={{ textAlign: "center" }}>Salle</label>
                                    <label className='label-restau column' style={{ textAlign: "center" }}>Cuisine</label>
                                </div>
                                <div className="row">
                                    <label className='label-restau column'>Création espace</label>
                                    <div className='switch-column'>
                                        {/* for Salle */}
                                        <IOSSwitch
                                            sx={{ m: 1 }}
                                            checked={formData.salle.espace}
                                            onChange={(e: any) => handleSalleSwitchChange(e, 'espace')}
                                        />
                                    </div>
                                    <div className='switch-column'>
                                        {/* for Cuisine */}
                                        <IOSSwitch
                                            sx={{ m: 1 }}
                                            checked={formData.cuisine.espace}
                                            onChange={(e: any) => handleCuisineSwitchChange(e, 'espace')}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <label className='label-restau column'>Création planning</label>
                                    <div className='switch-column'>
                                        {/* for Salle */}
                                        <IOSSwitch
                                            sx={{ m: 1 }}
                                            checked={formData.salle.planning}
                                            onChange={(e: any) => handleSalleSwitchChange(e, 'planning')}
                                        />
                                    </div>
                                    <div className='switch-column'>
                                        {/* for Cuisine */}
                                        <IOSSwitch
                                            sx={{ m: 1 }}
                                            checked={formData.cuisine.planning}
                                            onChange={(e: any) => handleCuisineSwitchChange(e, 'planning')}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <label className='label-restau column'>Couleur</label>
                                    <div style={{ width: "30%", display: "flex", justifyContent: "center" }}>
                                        <div className="color-select-container">
                                            <div className="color-display" style={{ backgroundColor: formData.salle.couleur }}></div>
                                            {/* for Salle */}
                                            <select
                                                className="color-select"
                                                value={formData.salle.couleur}
                                                onChange={handleSalleSelectChange}
                                            >
                                                {colorOptions.map((option) => (
                                                    <option className='restau-color-option' key={option.value} value={option.value} style={{ backgroundColor: option.value }}>

                                                    </option>
                                                ))}
                                            </select>
                                            <div className="color-select-arrow">{'>'}</div>
                                        </div>
                                    </div>

                                    <div style={{ width: "30%", display: "flex", justifyContent: "center" }}>
                                        <div className="color-select-container">
                                            <div className="color-display" style={{ backgroundColor: formData.cuisine.couleur }}></div>
                                            {/* for Cuisine */}
                                            <select
                                                className="color-select"
                                                value={formData.cuisine.couleur}
                                                onChange={handleCuisineSelectChange}
                                            >
                                                {colorOptions.map((option) => (
                                                    <option className='restau-color-option' key={option.value} value={option.value} style={{ backgroundColor: option.value }}>

                                                    </option>
                                                ))}
                                            </select>
                                            <div className="color-select-arrow">{'>'}</div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div style={{ marginTop: "20px", width: "100%" }}>
                                <label htmlFor="posteServiceSalle" className='label-restau'>Poste du chef de service salle</label>
                                <input
                                    type="text"
                                    id='posteServiceSalle'
                                    className='text-restau'
                                    placeholder='Poste'
                                    value={formData.salle.posteService}
                                    onChange={(e) => handleSalleInputTextChange(e, 'posteService')}
                                />
                            </div>

                            <div style={{ marginTop: "20px", width: "100%" }}>
                                <label htmlFor="nomChefService" className='label-restau'>Chef de la salle</label>
                                <div className='restau-column2'>
                                    <input
                                        type="text"
                                        id='nomChefService'
                                        className='text-restau'
                                        placeholder='Nom'
                                        style={{ marginRight: '20px' }}
                                        value={formData.salle.nomChefService}
                                        onChange={(e) => handleSalleInputTextChange(e, 'nomChefService')}
                                    />
                                    <input
                                        type="text"
                                        id='prenomChefService'
                                        className='text-restau'
                                        placeholder='Prenom'
                                        value={formData.salle.prenomChefService}
                                        onChange={(e) => handleSalleInputTextChange(e, 'prenomChefService')}
                                    />
                                </div>
                            </div>

                            <div style={{ marginTop: "20px", width: "100%" }}>
                                <label htmlFor="posteServiceCuisine" className='label-restau'>Poste du chef de service cuisine</label>
                                <input
                                    type="text"
                                    id='posteServiceCuisine'
                                    className='text-restau'
                                    placeholder='Poste'
                                    value={formData.cuisine.posteService}
                                    onChange={(e) => handleCuisineInputTextChange(e, 'posteService')}
                                />
                            </div>

                            <div style={{ marginTop: "20px", width: "100%" }}>
                                <label htmlFor="nomChefCuisine" className='label-restau'>Chef de cuisine</label>
                                <div className='restau-column2'>
                                    <input
                                        type="text"
                                        id='nomChefCuisine'
                                        className='text-restau'
                                        placeholder='Nom'
                                        style={{ marginRight: '20px' }}
                                        value={formData.cuisine.nomChefCuisine}
                                        onChange={(e) => handleCuisineInputTextChange(e, 'nomChefCuisine')}
                                    />
                                    <input
                                        type="text"
                                        id='prenomChefCuisine'
                                        className='text-restau'
                                        placeholder='Prenom'
                                        value={formData.cuisine.prenomChefCuisine}
                                        onChange={(e) => handleCuisineInputTextChange(e, 'prenomChefCuisine')}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='restau-submit-div'>
                    <button type='submit' className='restau-btn restau-btn-submit'>
                        Créer un nouvel espace restaurrant
                    </button>
                </div>
            </form>
        </div>
    );
}

const IOSSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#1DC9A0',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#1DC9A0',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));