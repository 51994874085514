import Permission from "./Permission";

export default class PermissionFamily {
    public id: number;
    public description: string;
    public permissions: Array<Permission>;

    constructor(id = 0, description = '', permissions: Array<Permission> = []) {
        this.id = id;
        this.description = description;
        this.permissions = permissions;
    }
}