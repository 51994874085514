import './MssAdminUser.css';
import classNames from "classnames";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import User from '../../../models/User';

interface IMssAdminUser {
  user?: User,
  id?: number;
  picture?: string;
  name?: string;
  role?: string;
  selected: boolean;
  onClick: React.MouseEventHandler<HTMLDivElement> | undefined;
  moreVert?: boolean;
}

function MssAdminUser(props: IMssAdminUser) {

  const MssAdminUserMoreVertIcon = () => props.moreVert ? <div className='MssAdminUserMoreVertIcon'><MoreVertIcon /></div> : null

  return (
    <div className={classNames({'MssAdminUser': true, 'MssAdminUserSelected': props.selected})} onClick={props.onClick}>
      <div className='MssAdminUserPicture' style={{backgroundImage: `url("data:image/png;base64,${props.user?.picture || props.picture}")`}}></div>
      <div className='MssAdminUserNameRole'>
        <div className='MssAdminUserName'>{props.user?.lastname + ' ' + props.user?.firstname || props.name}</div>
        <div className='MssAdminUserRole'>{props.user?.roleDepartment || props.role}</div>
      </div>
      {MssAdminUserMoreVertIcon()}
    </div>
  );
}

export default MssAdminUser;
