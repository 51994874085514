import MssAdminUsers from './mss-admin-users/MssAdminUsers';
import './MssAdmin.css';

import superAdminStarLogo from './images/SuperAdminStar.png';
import autorisationKeyLogo from './images/AutorisationKey.png';
import MssAdminUser from './mss-admin-user/MssAdminUser';
import { useEffect, useState } from 'react';
import MssAdminDepartment from './mss-admin-department/MssAdminDepartment';
import MssAdminLicence from './mss-admin-licence/MssAdminLicence';
import User from '../../models/User';
import Category from '../../enums/Category';
import { DepartmentService } from '../../utils/DepartmentService';
import axios from 'axios';
import PermissionType from '../../enums/PermissionType';
import PermissionArea from '../../models/PermissionArea';
import PermissionFamily from '../../models/PermissionFamily';
import Permission from '../../models/Permission';

const apiUrl = window.location.hostname === 'localhost' ? 'https://localhost:7142/api' : '/api';

function MssAdmin() {
  const [users, setUsers] = useState<Array<User>>([]);
  const [user, setUser] = useState<User>(new User());
  const [permissionAreas, setPermissionAreas] = useState<Array<PermissionArea>>([]);
  const [openedPermissionAreas, setOpenedPermissionAreas] = useState<Array<Number>>([]);

  const superAdmins = () => users.filter(user => user.category === Category.SUPER_ADMIN);

  const cadres = () => users.filter(user => user.category === Category.CADRE);

  const niveau1 = () => Object.values(users.filter(user => user.category === Category.NIVEAU_1).reduce((acc: any, user) => (acc[user.department] = acc[user.department] || { department: user.department, users: [] }, acc[user.department].users.push({ id: user.id, firstname: user.firstname, lastname: user.lastname, department: user.department, roleDepartment: user.roleDepartment, picture: user.picture, category: user.category }), acc), {}));

  const niveau2 = () => Object.values(users.filter(user => user.category === Category.NIVEAU_2).reduce((acc: any, user) => (acc[user.department] = acc[user.department] || { department: user.department, users: [] }, acc[user.department].users.push({ id: user.id, firstname: user.firstname, lastname: user.lastname, department: user.department, roleDepartment: user.roleDepartment, picture: user.picture, category: user.category }), acc), {}));

  const isSelected = (u: User) => u.id === user?.id;

  const togglePermissionArea = (permissionAreaId: number) => {
    let newOpenedPermissionAreas = [...openedPermissionAreas];
    if (newOpenedPermissionAreas.includes(permissionAreaId)) {
      newOpenedPermissionAreas = newOpenedPermissionAreas.filter(newOpenedPermissionArea => newOpenedPermissionArea !== permissionAreaId);
    } else {
      newOpenedPermissionAreas.push(permissionAreaId);
    }
    setOpenedPermissionAreas(newOpenedPermissionAreas);
  }

  const togglePermissionFamily = (permissionFamily: PermissionFamily) => {
    const familyPermissions = permissionFamily.permissions
      .map(permission => permission.id)
      .filter(permissionId => user?.permissions.includes(permissionFamily.id) ? user.permissions.includes(permissionId) : !user?.permissions.includes(permissionId));

    setPermissions([permissionFamily.id, ...familyPermissions]);
  }

  useEffect(() => {
    getUsers();
    getPermissions();
  }, [])

  const getUsers = () => {
    axios.get(apiUrl + '/admin/users').then(response => setUsers(
      response.data
        .map((u: any) => new User(u.id, u.firstName, u.lastName, u.department, u.roleDepartment, u.picture, u.role, u.permissions))
        .filter((u: any) => u.id > 1)
    ));
  }

  const getPermissions = () => {
    axios.get(apiUrl + '/admin/permissions').then(response => setPermissionAreas(transformPermission(response.data)));
  }

  const setPermission = (permissionId: number) => {
    axios.post(apiUrl + '/admin/permission', { userId: user?.id, permissionId }).then(getUsers);
  }

  const setPermissions = (permissionIds: Array<number>) => {
    axios.post(apiUrl + '/admin/permissions', { userId: user?.id, permissionIds }).then(getUsers);
  }

  function transformPermission(permissions: any) {
    const permissionAreas: Array<PermissionArea> = [];

    for (const area of permissions.filter((permission: any) => permission.type === PermissionType.AREA)) {
      const rootPermissions = permissions
        .filter((permission: any) => permission.type === PermissionType.PERMISSION && permission.parentId === area.id)
        .map((permission: any) => new Permission(permission.id, permission.description));

      const familyPermissions = permissions
        .filter((permissionFamily: any) => permissionFamily.type === PermissionType.FAMILY && permissionFamily.parentId === area.id)
        .map((permissionFamily: any) => new PermissionFamily(permissionFamily.id, permissionFamily.description,
          permissions
            .filter((permission: any) => permission.type === PermissionType.PERMISSION && permission.parentId === permissionFamily.id)
            .map((permission: any) => new Permission(permission.id, permission.description))
        ));

      permissionAreas.push(new PermissionArea(area.id, area.description, rootPermissions, familyPermissions));
    }

    return permissionAreas;
  }

  return (
    <div className="MssAdmin">
      <div className="MssAdminPanelUsers">
        <div className="MssAdminPanelUsersRow">
          <div className="MssAdminPanelUsersRowItem">
            <MssAdminUsers logo={superAdminStarLogo} name="Super admin">
              {superAdmins().map((u, key) => <MssAdminUser user={u} key={key} selected={isSelected(u)} onClick={() => setUser(u)} />)}
            </MssAdminUsers>
          </div>
          <div className="MssAdminPanelUsersRowItem">
            <MssAdminUsers name="Cadre / chef de services">
              {cadres().map((u, key) => <MssAdminUser user={u} key={key} selected={isSelected(u)} onClick={() => setUser(u)} />)}
            </MssAdminUsers>      
          </div>
        </div>
        <div className="MssAdminPanelUsersRow MssAdminPanelUsersRowColab">
          <div className="MssAdminPanelUsersRowItem">
            <MssAdminUsers name="Collaborateurs niveau 1" height={510} scroll={true}>
              {niveau1().map((department: any, key) => 
                <MssAdminDepartment key={key} name={DepartmentService.getDepartmentName(department.department)} visible={key === 0}>
                  {department.users.map((u: any, key: any) => <MssAdminUser user={u} key={key} selected={isSelected(u)} onClick={() => setUser(u)} />)}
                </MssAdminDepartment>
              )}
            </MssAdminUsers>
          </div>
          <div className="MssAdminPanelUsersRowItem">
            <MssAdminUsers name="Collaborateurs niveau 2" height={510} scroll={true}>
              {niveau2().map((department: any, key) => 
                <MssAdminDepartment key={key} name={DepartmentService.getDepartmentName(department.department)} visible={key === 0}>
                  {department.users.map((u: any, key: any) => <MssAdminUser user={u} key={key} selected={isSelected(u)} onClick={() => setUser(u)} />)}
                </MssAdminDepartment>
              )}
            </MssAdminUsers>
          </div>
        </div>
        <div className="MssAdminPanelUsersRow">
          <MssAdminLicence />
        </div>
      </div>
      <div className="MssAdminPanelAuthorisation">
        <div className="MssAdminPanelAuthorisationHeader">
          <div className="MssAdminPanelAuthorisationHeaderTitle">
            <div className="MssAdminPanelAuthorisationHeaderTitleLogo">
              <img src={autorisationKeyLogo} alt="" draggable="false" />
            </div>
            <div className="MssAdminPanelAuthorisationHeaderTitleLabel">Autorisations</div>
          </div>
          {user ?
            <div className="MssAdminPanelAuthorisationHeaderUser">
              <div className="MssAdminPanelAuthorisationHeaderUserInfo">
                <div className="MssAdminPanelAuthorisationHeaderUserInfoName">{user.lastname} {user.firstname}</div>
                <div className="MssAdminPanelAuthorisationHeaderUserInfoRole">{user.roleDepartment}</div>
              </div>
              <div className="MssAdminPanelAuthorisationHeaderUserPicture" style={{backgroundImage: `url("data:image/png;base64,${user.picture}")`}}>
              </div>
            </div>
            : null
          }
        </div>
        <div className="MssAdminPanelAuthorisationBody">
          {permissionAreas.map((area, key) => 
            <div key={key} className="AutorisationDropdownSection">
              <div className="AutorisationDropdownHeader" onClick={() => togglePermissionArea(area.id)}>
                <div className="AutorisationDropdownHeaderTitle">{area.description}</div>
                <div>{openedPermissionAreas.includes(area.id) ? '▲' : '▼'}</div>
              </div>
              {openedPermissionAreas.includes(area.id) && user ?
                <div className="AutorisationDropdownContent">
                  {area.permissions.length ?
                    <div className="AutorisationDropdownContentAutorisations">
                      {area.permissions.map((permission1, key) =>
                        <div key={key} className="AutorisationDropdownContentAutorisation" onClick={() => setPermission(permission1.id)}>
                          <div className="AutorisationDropdownContentAutorisationName">{permission1.description}</div>
                          <input type="checkbox" checked={user.permissions.includes(permission1.id)} onChange={() => {}} />
                        </div>
                      )}
                    </div> : null
                  }
                  {area.permissionFamilies.length && user ?
                    <div className="AutorisationDropdownContentAutorisationFamilyList">
                      {area.permissionFamilies.map((permissionFamily, key) =>
                        <div key={key} className='AutorisationDropdownContentAutorisationFamilyWrapper'>
                          <div className="AutorisationDropdownContentAutorisationFamily" onClick={() => togglePermissionFamily(permissionFamily)}>
                            <div className="AutorisationDropdownContentAutorisationFamilyName">{permissionFamily.description}</div>
                            <input type="checkbox" checked={user.permissions.includes(permissionFamily.id)} onChange={() => {}} />
                          </div>
                          {permissionFamily.permissions.map((permission2, key) =>
                            <div key={key} className="AutorisationDropdownContentAutorisationChild" onClick={() => setPermission(permission2.id)}>
                              <div className="AutorisationDropdownContentAutorisationName">{permission2.description}</div>
                              <input type="checkbox" checked={user.permissions.includes(permission2.id)} onChange={() => {}} />
                            </div>
                          )}
                        </div>
                      )}
                    </div> : null
                  }
                </div> : null
              }
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default MssAdmin;
