import Department from "../enums/Department";

export class DepartmentService {

    static getDepartmentName(departmentNumber: Department) {
        switch (departmentNumber) {
            case Department.NONE:
                return '';
            case Department.DIRECTION:
                return 'Direction';
            case Department.CADRE:
                return 'Cadre';
            case Department.CONCIERGERIE:
                return 'Conciergerie';
            case Department.SPA:
                return 'Spa';
            case Department.RESTAURANT:
                return 'Restaurant';
            case Department.HOUSEKEEPING:
                return 'Housekeeping';
            default:
                return 'DepartmentName'
        }
    }
}
