const enum Department {
    NONE = 0,
    DIRECTION = 1,
    CADRE = 2,
    CONCIERGERIE = 3,
    SPA = 4,
    RESTAURANT = 5,
    HOUSEKEEPING = 6
}

export default Department;