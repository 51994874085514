import Permission from "./Permission";
import PermissionFamily from "./PermissionFamily";

export default class PermissionArea {
    public id: number;
    public description: string;
    public permissions: Array<Permission>;
    public permissionFamilies: Array<PermissionFamily>;

    constructor(id = 0, description = '', permissions: Array<Permission> = [], permissionFamilies: Array<PermissionFamily> = []) {
        this.id = id;
        this.description = description;
        this.permissions = permissions;
        this.permissionFamilies = permissionFamilies;
    }
}